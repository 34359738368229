<template>
  <div>
    <v-divider class="my-5" />
    <label-icon label="Terapias complementares" icon="bubble_chart" bold />
    <div v-if="hasComplementaryTherapies">
      <v-list two-line>
        <v-list-group
          v-for="(ct, index) in complementaryTherapies"
          :key="index"
          class="elevation-1"
        >
          <template v-slot:activator>
            <v-list-item class="pa-7">
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">
                  {{ ct.complementarTherapy.name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <div class="pa-3 black--text body-2">
            <text-editor
              :value="ct.therapistComments"
              label="Comentários aos terapeutas"
              return-value="therapistComments"
              class="mb-3"
              @save="changeProperties"
              @open="selectIndex(index)"
            />
            <text-editor
              :value="ct.patientComments"
              label="Comentários ao paciente"
              return-value="patientComments"
              class="mb-3"
              @save="changeProperties"
              @open="selectIndex(index)"
            />

            <switch-editor
              :value="ct.hidePatient"
              label="Esconder ao paciente na diagnose"
              return-value="hidePatient"
              class="mb-3"
              @save="changeProperties"
              @open="selectIndex(index)"
            />
            <v-row justify="center" class="py-5">
              <v-btn
                dark
                color="red"
                @click="removeComplementaryTherapy(index)"
              >
                <v-icon class="mr-2">delete</v-icon>
                Remover terapia
              </v-btn>
            </v-row>
          </div>
        </v-list-group>
      </v-list>
    </div>
    <div v-else class="mt-5">Nenhuma terapia complementar adicionada.</div>
  </div>
</template>

<script>
export default {
  name: "ComplementaryTherapiesSection",
  data: () => ({
    selectedIndex: -1,
  }),
  computed: {
    hasComplementaryTherapies() {
      return this.$store.getters["diagnosis/hasComplementaryTherapies"];
    },
    complementaryTherapies() {
      console.log(this.$store.state.diagnosis.diagnose);
      return this.$store.state.diagnosis.diagnose.complementarTherapies;
    },
  },
  methods: {
    changeProperties(property) {
      this.$store.commit("diagnosis/changePropertyComplementaryTherapy", {
        index: this.selectedIndex,
        property,
      });
    },
    selectIndex(index) {
      this.selectedIndex = index;
    },
    removeComplementaryTherapy(index) {
      this.$store.commit("diagnosis/removeComplementaryTherapy", index);
    },
  },
};
</script>

<style scoped></style>
